export interface DeviceConfig {
  options: {
    postOrderTransitionTime: number;
  }
  layout: {
    panels: Panel[];
  };
}

export interface Panel {
  type: PanelTypes
  conditions:PanelConditions[];
  menu_items?: MenuItemPanel[];
  position:PanelPositionProps;
  size:PanelSizeProps;
}

export interface PanelPositionProps {
  top: string;
  left: string;
}

export interface PanelSizeProps {
  width: string;
  height: string;
}

export interface MenuItemPanel {
  menu_item_id: string,
  price_text?: {
      position: PanelPositionProps;
      size: PanelSizeProps;
      font_size: string;
      font_size_secondary?: string;
      font_weight: string;
      color?: string;
  },
  unavailable_overlay: {
    url:string;
    position: PanelPositionProps;
    size: PanelSizeProps;
  }
}

export enum PanelTypes {
  ImagePanel = "ImagePanel",
  OrderPanel = "OrderPanel",
  RotatingPanel = "RotatingPanel",
  MenuItemPrice = "MenuItemPrice"
}

export enum PanelConditions {
  ActiveOrder = "ActiveOrder",
  NoActiveOrder = "NoActiveOrder",
  PostOrderTransition = "PostOrderTransition",
  ActiveConversation = "ActiveConversation",
  NoActiveConversation = "NoActiveConversation"
}