import { OrderItem } from '../order';
import { Panel } from './DeviceConfig';
import { OrderContext } from './OrderContext';
import { ConversationContext } from './ConversationContext';
import { useContext, useEffect, useState } from 'react';

export interface OrderPanel extends Panel {
}

function getChildItemsString(item:OrderItem) {
  let s = '';
  if (item.modifier_groups?.[Symbol.iterator]) {
      for (const mg of item.modifier_groups) {
          if (mg.modifiers) {
            for (const mi of mg.modifiers) {
                if (s && s.length) {
                    s += ', ';
                }
                s += mi.name;
                if (mi.price > 0) {
                    s += ' (+$' + formatPrice(mi.price) + ')';
                }
            }
          }
      }
  }

  return s;
}

function formatPrice(price : number | undefined) {
  if (typeof price !== 'number' || isNaN(price)) {
    return price;
  } else {
    return price?.toFixed(2);
  }
}

enum VoiceState {
  none = 0,
  speaking = 1,
  listening = 2
}
export const OrderPanelComponent:React.FC<OrderPanel> = ({ position, size, conditions }: OrderPanel) => {
  
  const context = useContext(OrderContext);
  const order = context?.order;
  const conversationContext = useContext(ConversationContext);
  const conversation = conversationContext?.conversation;
  const [showVoiceComplications,setShowVoiceComplications] = useState(false);
  const voiceState = (!conversation || conversation.who === 'Casual') ? VoiceState.speaking : VoiceState.listening;

  const listStyles = {
   listStyle: 'none',
  };


  let dialogColor = (voiceState === VoiceState.listening) ? '#01847E' : '#F25600';

  useEffect(() => {
      setShowVoiceComplications(conversation?.text?.length ? true : false);
  }, [conversation]);

  return (
    <div
      className="order-panel"
      style={{
        left: position.left,
        top: position.top,
        width: size.width,
        height: size.height,
      }}
    >
      <div className="order-header">
        <div className="order-number">Your Order {order?.id ? "#" : undefined}{order?.id}</div>
        <div className="order-total">Total ${formatPrice(order?.total)}</div>
      </div>
      <div style={{ height: "100%", overflow: 'hidden' }}>
        {showVoiceComplications && (
          <div className="float-left" style={{ width: "calc(50% - 30px)", height: "100%" }}>
            <div style={{height:'calc(100% - 50px)',border: '2px solid #ECEBE9', boxSizing: 'border-box', borderRadius: '15px', padding: '20px', margin: '30px 0 30px 30px', textAlign: 'left', color:dialogColor}}>
            <div style={{fontSize: '25px', padding: '20px 0'}}>{(voiceState === VoiceState.speaking) ? 'Chicken Bot is speaking' : 'Chicken Bot is listening'}</div>
            <div style={{fontSize: '36px', padding: '30px 0'}}>{conversation?.text}</div>
            <div style={{width: '142px', padding: '80px 0'}}><img alt='sound waves' src={(voiceState===VoiceState.speaking?'/digital-assets/speaking.gif':'/digital-assets/listening.gif')} style={{width:'100%',height:'100%', objectFit: 'cover'}}></img></div>
            </div>
          </div>
        )}
        <div className="float-right order-items" style={{ width: showVoiceComplications ? "calc(50% + 30px)" : "100%" }}>
          <ul style={listStyles}>
            {order?.items?.map((item, index) => (
              <li key={index}>
                <div className="item-container">
                  <div className="item-quantity">{item.quantity}</div>
                  <div className="item-name">
                    {item.name}
                  </div>
                  <div className="item-price">${formatPrice(item.price)}</div>
                </div>
                <div className="item-children">{getChildItemsString(item)}</div>
              </li>
            ))}
          </ul>
          <p />
        </div>
      </div>
      {showVoiceComplications && false && (
        <div className="order-footer">
          <div>Our team are here to help. Just Say 'Crew Member'</div>
        </div>
      )}
    </div>
  );
}