import { createContext } from 'react';

export interface Conversation {
  status:string;
  text:string;
  who:string;
}

export interface ConversationContextType {
    conversation: Conversation|undefined;
    setConversation: React.Dispatch<React.SetStateAction<Conversation|undefined>>;
  }

export const ConversationContext = createContext<ConversationContextType | undefined>(undefined);