// Function to store clientSecret in localStorage
export const storeClientSecretInLocal = (clientSecret: string) => {
  localStorage.setItem('clientSecret', clientSecret);
};

// Function to retrieve clientSecret from localStorage
export const getClientSecretFromLocal = () => {
  return localStorage.getItem('clientSecret');
};

// Function to clear clientSecret from localStorage
export const clearClientSecretFromLocal = () => {
  localStorage.removeItem('clientSecret');
};

export const empty_image_gif = 'data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA=';