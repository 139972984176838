
const LoadingLogo = ({ text }: { text?: string }) => {
  return (
    <div style={{
      position: 'fixed',
      height: '100%',
      width: '100%',
      inset: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 'min(6vw,6vh)'
    }}>
      <div style={{ margin: 'auto' }}>
        <img style={{
        width: 'min(40vw,40vh)'
        }} src="/digital-assets/cm-logo-25.svg" alt="Checkmate Logo" />
        <p>{text}</p>
      </div>
    </div>
  );
};

export default LoadingLogo;