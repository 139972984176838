import React, { useState, useEffect } from 'react';
import '../App.css';
import { ConnectionState } from '../Components/ConnectionState';
import { Order } from '../order';
import CableApp from "../actionCable";
import { DeviceConfig, PanelTypes } from '../Components/DeviceConfig';
import axios from 'axios';
import { DeviceConfigContext } from '../Components/DeviceConfigContext';
import { OrderContext } from '../Components/OrderContext';
import { MenuContext } from '../Components/MenuContext';
import { Panels } from '../Components/Panels';
import { getClientSecretFromLocal, clearClientSecretFromLocal } from '../utilities';
import { Conversation, ConversationContext } from '../Components/ConversationContext';
import { Menu } from '../menu';
import LoadingLogo from '../Components/LoadingLogo';

class WebsocketPayload {
  type?:string;
  order?:Order;
  conversation:Conversation[] = [];
  menu?:Menu;
  device_config?:DeviceConfig;
  message?:string;
  error_code?:number;
}

export const Home = () => {
  const [isConnected, setIsConnected] = useState(false);
  const [deviceConfig, setDeviceConfig] = useState<DeviceConfig|undefined>();
  const [menu, setMenu] = useState<Menu|undefined>();
  const [order, setOrder] = useState<Order|undefined>(undefined);
  const [conversation, setConversation] = useState<Conversation>();

  useEffect(() => {
    function onOrderEvent(value: Order | undefined): void {
      if (value) {
        if (value.isActive === undefined) value.isActive = true;
        setOrder(value);
      } else {
        setOrder(prevOrder => {
          if (prevOrder && prevOrder.isActive) {
            return Object.assign({}, prevOrder,{isActive:false, completed_at: new Date()});
          } else {
            return undefined;
          }
        });
      }
    }
    const subscription = CableApp.cable.subscriptions.create(
      {
        channel: "ActiveOrderChannel",
        token: getClientSecretFromLocal(),
      },
      {
        connected: () => {
          setIsConnected(true);
          console.log("Connected to WebSocket");
        },
        disconnected: () => {
          setIsConnected(false);
          console.log("Disconnected from WebSocket");
        },
        received: (data: Order|WebsocketPayload) => {
          let dataType = (data as WebsocketPayload)?.type;
          let order = (data as WebsocketPayload)?.order;
          let conversation = (data as WebsocketPayload)?.conversation;
          let config = (data as WebsocketPayload)?.device_config;
          let menu = (data as WebsocketPayload)?.menu;
          let error_code = (data as WebsocketPayload)?.error_code;

          console.log(`Received a WebsocketPayload ${JSON.stringify(data)} with config: ${JSON.stringify(config)}\nmenu: ${JSON.stringify(menu)}\norder: ${JSON.stringify(order)}\nconversation: ${JSON.stringify(conversation)}\n\n`);

          if (dataType === 'error') {
            if (error_code === 401) {
              clearClientSecretFromLocal();
              window.location.href = '/login';
              console.error(`Websocket authorization failed: Invalid token provided.`);
              return;
            } else {
              console.error(`Websocket reported an error: ${data}`);
              return;
            }
          }
          if (config !== undefined) {
            setDeviceConfig(config);
          }

          if (menu !== undefined) {
            setMenu(menu);
          }

          if (order === null || data === null) {
            onOrderEvent(order);
          } else {
            if ((data as Order)?.id) {
              // older Web backend sent the order directly as the payload
              onOrderEvent(data as Order);
            } else {
              if (order?.items || conversation?.length) {
                onOrderEvent(order);

                if (conversation) {
                  setConversation(conversation[conversation.length-1]);
                } else {
                  setConversation(undefined);
                }
              }
            }
          }
        },
      }
    );

    return () => {
      subscription.unsubscribe(); // Use `unsubscribe` here
    };
  }, []);


  useEffect(() => {
    if (deviceConfig?.options?.postOrderTransitionTime) {
      const now = new Date();
      if (
        order &&
        !order.isActive &&
        order.completed_at &&
        now.getTime() - (new Date(order.completed_at)).getTime() <
          deviceConfig?.options?.postOrderTransitionTime
      ) {
        const postOrderTransitionTimer = setTimeout(() => {
          setOrder(Object.assign({}, order));
          setConversation(undefined);
        }, deviceConfig?.options?.postOrderTransitionTime);

        return () => clearTimeout(postOrderTransitionTimer); // Cleanup on unmount or before next effect run
      }
    }
  }, [deviceConfig, order]);

  return (
    <div className="App">
      <DeviceConfigContext.Provider value={{ deviceConfig, setDeviceConfig }}>
        <MenuContext.Provider value={{ menu, setMenu }}>
          <OrderContext.Provider value={{ order, setOrder }}>
            <ConversationContext.Provider value={{ conversation, setConversation }}>
              {deviceConfig ? <Panels /> : <LoadingLogo text={isConnected ? 'Configuring...' : 'Initializing...'} />}
            </ConversationContext.Provider>
          </OrderContext.Provider>
        </MenuContext.Provider>
      </DeviceConfigContext.Provider>
    </div>
  );
}
