import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getClientSecretFromLocal, storeClientSecretInLocal } from '../utilities';
import QRCode from 'react-qr-code';
import { Box } from '@mui/material';

const QrCode = () => {
  var sp = new URLSearchParams(window.location.search)
  const [registrationCode, setRegistrationCode] = useState<string | null>(sp.get('registration_code'));

  useEffect(() => {
    const fetchRegistrationCode = async () => {
      try {
        const clientId = process.env.REACT_APP_CLIENT_ID;
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_HOST}/api/v1/dmb/registration_code`,
          {
            headers: {
              "client-id": clientId, // Add the clientId as a custom header
            },
          }
        );
        if (response.data && response.data.registration_code) {
          setRegistrationCode(response.data.registration_code);
        }
      } catch (error) {
        setErrorMessage("Could not fetch the Registration Code");
        console.error('Error fetching Registration Code:', error);
      }
    };

    if (!registrationCode) { // Only fetch registration code if it's not set yet
      fetchRegistrationCode();
    }
  }, [registrationCode]);

  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (!registrationCode) return;

    const pollingInterval = 5000; // Poll every 5 seconds
    const timeoutDuration = 3600000; // Timeout after 60 minutes
    const startTime = Date.now();

    const fetchSessionId = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_HOST}/api/v1/dmb/poll_session_status?registration_code=${registrationCode}`
        );
        if (response.ok) {
          const data = await response.json();
          if (data.session_id) {
            storeClientSecretInLocal(data.session_id);
            navigate("/");
            return true;
          }
        }
      } catch (error) {
        console.error("Error fetching session ID:", error);
      }
      return false;
    };

    const interval = setInterval(async () => {
      const shouldStop = await fetchSessionId();

      if (shouldStop || Date.now() - startTime > timeoutDuration) {
        clearInterval(interval);
        if (!shouldStop) {
          setErrorMessage("Session has timed out. Please reload to continue.");
        }
      }
    }, pollingInterval);

    return () => clearInterval(interval);
  }, [navigate, registrationCode]);

  const registrationCodeUrl = `${process.env.REACT_APP_MM_FRONTEND_HOST}/link_dmb_device?registration_code=${registrationCode}`;

  return (
    <Box
      sx={{
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "4vw"
      }}
    >
      <br />
      {errorMessage ? (
        <p style={{ color: "red" }}>{errorMessage}</p>
      ) : registrationCode ? (
        <QRCode
          value={registrationCodeUrl || ""}
          size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          viewBox={`0 0 256 256`}
        />
      ) : (
        <p>Loading QR Code...</p>
      )}
      { registrationCode && !errorMessage && (
        <>
          <h1>{registrationCode}</h1>
        </>
      )}
    </Box>
  );
};

export const Login = () => {
  const navigate = useNavigate();

  const clientSecret = getClientSecretFromLocal();
  useEffect(() => {
    if (clientSecret) {
      navigate('/');
    }
  }, [clientSecret, navigate]);

  return (
      <div className='login-box'>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
        </Box>
        <QrCode />
      </div>
  );
}
