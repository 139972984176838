import { useContext } from 'react';
import { MenuItem } from '../menu';
import { Panel } from './DeviceConfig'
import { MenuContext } from './MenuContext';
import { empty_image_gif } from '../utilities';
export interface ImagePanel extends Panel {
  url:string,
  loop_video:boolean
}

function integerPart(price:number|undefined|null):string {
  if (!price) return '';

  return Math.trunc(price).toFixed(0);
}
function decimalPart(price:number|undefined|null):string {
  if (!price) return '';

  return (price % 1).toFixed(2).slice(1);
}

export const ImagePanelComponent:React.FC<ImagePanel> = ({ position, size, conditions, url, loop_video, menu_items }: ImagePanel) => {

  const menuContext = useContext(MenuContext);

  function getMenuItemById(id:string):MenuItem|undefined {
    return menuContext?.menu?.items?.find(i => i.id === id);
  }

  return (
    <div className='image-panel' style={{
      left: position.left,
      top: position.top,
      width: size.width,
      height: size.height,
      backgroundImage: (url?.endsWith('png')||url?.endsWith('jpg')) ? `url(${url})` : undefined
    }}>
      {url?.endsWith('mp4') ?
      <video autoPlay loop={loop_video} muted id="image-panel-video">
        <source src={`${url}`} type="video/mp4" />
      </video> : undefined}

    {menu_items?.map((menu_item, menu_item_index) => {
      const mi = getMenuItemById(menu_item.menu_item_id);
        return (
          menu_item.price_text &&
          <div key={`menu-item-price-${menu_item_index}`} className="menu-item-price"style={{
            left: menu_item.price_text?.position.left,
            top: menu_item.price_text?.position.top,
            width: menu_item.price_text?.size.width,
            height: menu_item.price_text?.size.height,
            fontSize: menu_item.price_text?.font_size,
            fontWeight: menu_item.price_text?.font_weight,
            color: menu_item.price_text?.color,
            }}>
            <span>{integerPart(mi?.price)}</span>
            <span style={{fontSize:menu_item.price_text?.font_size_secondary?menu_item.price_text?.font_size_secondary:menu_item.price_text?.font_size}}>{decimalPart(mi?.price)}</span>
          </div>
        )}
    )}
    {menu_items?.map((menu_item, menu_item_index) => {
      const mi = getMenuItemById(menu_item.menu_item_id);
        return (
          mi?.soldOut &&
          <div key={`menu-item-unavailable-${menu_item_index}`} className="menu-item-unavailable"style={{
            left: menu_item.unavailable_overlay?.position?.left,
            top: menu_item.unavailable_overlay?.position?.top,
            width: menu_item.unavailable_overlay?.size?.width,
            height: menu_item.unavailable_overlay?.size?.height,
            }}>
              <img alt="unavailable" src={menu_item.unavailable_overlay?.url || empty_image_gif}></img>
          </div>
        )}
    )}
    </div>
  );
};