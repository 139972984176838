import { useContext } from "react";
import { DeviceConfigContext, DeviceConfigContextType } from "./DeviceConfigContext";
import { ImagePanel, ImagePanelComponent } from "./ImagePanel";
import { OrderPanel, OrderPanelComponent } from "./OrderPanel";
import { RotatingPanel, RotatingPanelComponent } from "./RotatingPanel";
import { OrderContext, OrderContextType } from "./OrderContext";
import { ConversationContext, ConversationContextType } from "./ConversationContext";
import { Panel, PanelConditions } from "./DeviceConfig";

export function renderPanel(panel:Panel,panelIndex:number,deviceConfigContext?:DeviceConfigContextType, orderContext?:OrderContextType, conversationContext?:ConversationContextType) {
  if (matchesConditions(panel.conditions, deviceConfigContext, orderContext, conversationContext)) {
    switch (panel.type) {
      case "RotatingPanel":
        const rotatingPanel = panel as RotatingPanel;
        return <RotatingPanelComponent key={panelIndex} {...rotatingPanel} />;
      case "ImagePanel":
        const imagePanel = panel as ImagePanel;
        return <ImagePanelComponent key={panelIndex} {...imagePanel} />;
      case "OrderPanel":
        const orderPanel = panel as OrderPanel;
        return <OrderPanelComponent key={panelIndex} {...orderPanel} />;
      default:
        return <div></div>;
    }
  } else return undefined;
}

function matchesConditions(conditions:PanelConditions[],deviceConfigContext?:DeviceConfigContextType, orderContext?:OrderContextType, conversationContext?:ConversationContextType) {

  let matchesConditions = true;
  if (conditions?.includes(PanelConditions.ActiveOrder)) {
    matchesConditions &&= (orderContext?.order?.isActive) || false;
  }

  if (conditions?.includes(PanelConditions.NoActiveOrder)) {
    matchesConditions &&= (!orderContext?.order?.isActive) || false;
  }

  if (conditions?.includes(PanelConditions.ActiveConversation)) {
    matchesConditions &&= (conversationContext?.conversation?.text?.length && conversationContext?.conversation?.text?.length > 0) || false;
  }

  if (conditions?.includes(PanelConditions.NoActiveConversation)) {
    matchesConditions &&= (!conversationContext?.conversation?.text?.length || conversationContext?.conversation?.text?.length <= 0) || false;
  }

  if (conditions?.includes(PanelConditions.PostOrderTransition)) {
    let timeSinceCompletion = null;
    const now = new Date();
    if (orderContext?.order != null &&
      orderContext?.order?.isActive === false &&
      orderContext?.order?.completed_at) {
          timeSinceCompletion = now.getTime()-(new Date(orderContext.order.completed_at)).getTime();
      }
      matchesConditions &&= ((timeSinceCompletion != null) && (timeSinceCompletion < (deviceConfigContext?.deviceConfig?.options?.postOrderTransitionTime || 0))) || false;
  }

  return matchesConditions;
}

export function Panels() {
  const deviceConfigContext = useContext(DeviceConfigContext);
  const orderContext = useContext(OrderContext);
  const conversationContext = useContext(ConversationContext);

  return (
    <>
      {deviceConfigContext?.deviceConfig?.layout?.panels?.map((panel, panelIndex) => {
        return renderPanel(panel, panelIndex, deviceConfigContext, orderContext, conversationContext);
      })}
    </>
  );
}