import { useEffect } from 'react';
import { clearClientSecretFromLocal } from '../utilities';
import { useNavigate } from 'react-router-dom';

export const Logout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const handleLogout = () => {
      clearClientSecretFromLocal();
      navigate('/login');
    }
      handleLogout();
  }, [navigate]);
  return null;
}
