import { useContext, useEffect, useState } from 'react';
import { Panel } from './DeviceConfig'
import { DeviceConfigContext } from './DeviceConfigContext';
import { OrderContext } from './OrderContext';
import { ConversationContext } from './ConversationContext';
import { renderPanel } from './Panels';
export interface RotatingPanel extends Panel {
  rotation_interval:number;
  child_panels:Panel[];
}

export const RotatingPanelComponent:React.FC<RotatingPanel> = ({ position, size, conditions, rotation_interval, child_panels }: RotatingPanel) => {

  const deviceConfigContext = useContext(DeviceConfigContext);
  const orderContext = useContext(OrderContext);
  const conversationContext = useContext(ConversationContext);

  const [visibleChildPanelIndex, setVisibleChildPanelIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setVisibleChildPanelIndex((prevValue: number) => ((prevValue + 1) < child_panels?.length) ? (prevValue + 1) : 0);
    }, rotation_interval);

    return () => clearInterval(intervalId); // Clear the interval on unmount
  }, [child_panels?.length, rotation_interval]);

  const child_panel = child_panels?.at(visibleChildPanelIndex);

  return (
    <div className='rotating-panel' style={{
      left: position.left,
      top: position.top,
      width: size.width,
      height: size.height
    }}>
      {child_panel && renderPanel(child_panel, visibleChildPanelIndex, deviceConfigContext, orderContext, conversationContext)}
    </div>
  );
};